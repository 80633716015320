<template>
  <v-container class="docUserManage">
    <v-row style="margin-bottom: 8px;">
      <v-col>
        <v-btn :style="PersonalInfo.lastConfirmationType === 'Accepted' ? panelGreen : (PersonalInfo.lastConfirmationType === 'VerifiedBySystem' ? panelGreen : panelRed)" @click="Scroll('one')">اطلاعات شخصی
        </v-btn>
      </v-col>
      <v-col>
        <v-btn :style="PersonalInfo.emailConfirmed  ? panelGreen : panelRed" @click="Scroll('two')">ایمیل</v-btn>
      </v-col>
      <v-col>
        <v-btn :style="address.lastConfirmationType === 'Accepted' ? panelGreen : panelRed" @click="Scroll('tree')">اطلاعات
          تماس
        </v-btn>
      </v-col>
      <v-col>
        <v-btn :style="images.lastConfirmationType === 'Accepted' ? panelGreen : panelRed" @click="Scroll('image')">عکس ها
        </v-btn>
      </v-col>
      <v-col>
        <v-btn :style="panelGreen" @click="Scroll('bank')">اطلاعات بانکی</v-btn>
      </v-col>
      <v-col>
        <v-btn :style="panelGreen" @click="Scroll('access')">دسترسی ها</v-btn>
      </v-col>
    </v-row>
    <v-card style="padding: 1rem 2rem;">
      <div style="margin:10px 0px">
        <v-row style="width:200px;justify-content: space-between">
          <h4 class="panelTitle">سطح کاربری: </h4> <h1 style="font-size: 20px">{{ userLevel }}</h1>
        </v-row>
      </div>
      <hr>
      <div style="margin-top:20px">
        <v-row>
          <h4 class="panelTitle">پرونده کاربر</h4>
        </v-row>
      </div>
      <div style="margin-top:16px;margin-bottom: 1rem;" id="two">
        <v-row>
          <v-col cols="1">
            <span>وضعیت</span>
          </v-col>
          <v-col cols="3">
            <input class="disableInput" :style="PersonalInfo.lastConfirmationType === 'Accepted' ? 'color : green' : (PersonalInfo.lastConfirmationType === 'VerifiedBySystem' ? 'color : green' :'color : red')" :value="PersonalInfo.lastConfirmationType === 'Editable' ? 'قابل ویرایش' : (PersonalInfo.lastConfirmationType === 'Accepted' ? 'تایید شده' : (PersonalInfo.lastConfirmationType === 'ConfirmRequest' ? 'درخواست بررسی' : (PersonalInfo.lastConfirmationType === 'VerifiedBySystem' ? 'تایید شده توسط سیستم' : 'رد شده')))">
          </v-col>
          <v-col cols="1">
            <span>جنسیت</span>
          </v-col>
          <v-col cols="3">
            <input class="disableInput" :value="PersonalInfo.gender === 'Male' ? 'مرد' : 'زن'">
          </v-col>
          <v-col></v-col>
        </v-row>
        <v-row>
          <v-col cols="1">
            <span>نام</span>
          </v-col>
          <v-col cols="3">
            <input class="disableInput" :value="PersonalInfo.firstName">
          </v-col>
          <v-col cols="1">
            <span >نام خانوادگی</span>
          </v-col>
          <v-col cols="3">
            <input class="disableInput" :value="PersonalInfo.lastName">
          </v-col>
          <v-col cols="1">
            <span>کد ملی</span>
          </v-col>
          <v-col cols="3" style="max-width:250px;">
            <input class="disableInput" :value="PersonalInfo.nationalId">
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1">
            <span >تاریخ تولد</span>
          </v-col>
          <v-col cols="3">
            <input class="disableInput" :value="getBirthdate()">
          </v-col>
          <v-col cols="1">
            <span >تلفن همراه</span>
          </v-col>
          <v-col cols="3">
            <input class="disableInput" :value="this.$route.params.username">
          </v-col>
          <v-col></v-col>
        </v-row>
        <v-row style="justify-content:flex-start;">
          <v-col cols="1">
            <span>توضیحات</span>
          </v-col>
          <v-col>
            <input class="Input" id="personalInfoComment" v-model="PersonalInfoCommentConfirm">
          </v-col>
          <v-col>
            <v-btn color="success" @click="AcceptConfirmPersonalInfo()" v-if="PersonalInfo.lastConfirmationType !== 'Accepted' && PersonalInfo.lastConfirmationType !== 'VerifiedBySystem'">تایید درخواست
            </v-btn>
          </v-col>
          <v-col>
            <v-btn @click="RejectConfirmPersonalInfo()" color="error">رد درخواست
            </v-btn>
          </v-col>
          <v-col>
            <v-btn @click="getDataModal(PersonalInfo.confirmations)" style="color:white;background-color: #02b5a0!important;">تاریخچه
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <hr>
      <div style="margin-top: 2rem;margin-bottom: 1rem;" id="tree">
        <v-row>
          <h4 class="panelTitle">اطلاعات ایمیل</h4>
        </v-row>
        <v-row>
          <v-col cols="1">
            <span>وضعیت</span>
          </v-col>
          <v-col cols="3">
            <input :style="PersonalInfo.emailConfirmed ? 'color : green ' : 'color : red'" class="disableInput" :value="PersonalInfo.emailConfirmed === true ? 'تایید شده' : 'تایید نشده'">
          </v-col>
          <v-col cols="1">
            <span>ایمیل</span>
          </v-col>
          <v-col cols="4" >
            <input style="width:230px;" class="disableInput" :value="PersonalInfo.email">
          </v-col>
        </v-row>
      </div>
      <hr>
      <div style="margin-top: 2rem;margin-bottom: 1rem" id="image">
        <v-row>
          <h4 class="panelTitle">اطلاعات تماس</h4>
        </v-row>
        <v-row>
          <v-col cols="1">
            <span>وضعیت</span>
          </v-col>
          <v-col cols="3">
            <input class="disableInput" :style="address.lastConfirmationType === 'Accepted' ? 'color : green' :'color : red'" :value="address.lastConfirmationType === 'Editable' ? 'قابل ویرایش' : (address.lastConfirmationType === 'Accepted' ? 'تایید شده' : (address.lastConfirmationType === 'ConfirmRequest' ? 'درخواست بررسی'  : 'رد شده'))">
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1">
            <span>آدرس</span>
          </v-col>
          <v-col cols="7">
            <input class="disableInput" :value="address.address">
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1">
            <span >تلفن ثابت</span>
          </v-col>
          <v-col cols="3">
            <input class="disableInput" :value="address.phone">
          </v-col>
          <v-col cols="1">
            <span>کد پستی</span>
          </v-col>
          <v-col cols="3">
            <input class="disableInput" :value="address.postalCode">
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1">
            <span >توضیحات</span>
          </v-col>
          <v-col>
            <input class="Input" v-model="addressCommentConfirm">
          </v-col>
          <v-col>
            <v-btn color="success" @click="AcceptConfirmAddress()" v-if="address.lastConfirmationType !== 'Accepted' && address.lastConfirmationType !== 'VerifiedBySystem'">تایید درخواست
            </v-btn>
          </v-col>
          <v-col>
            <v-btn color="error" @click="RejectConfirmAddress()" v-if="address.lastConfirmationType != 'Rejected'">رد درخواست
            </v-btn>
          </v-col>
          <v-col>
            <v-btn style="color:white;background-color: #02b5a0!important;" @click="getDataModal(address.confirmations)">تاریخچه
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <hr>
      <div style="margin-top: 2rem;margin-bottom: 1rem" id="bank">
        <v-row>
          <v-col>
            <h4 class="panelTitle">عکس های کاربر</h4>
          </v-col>
          <v-col cols="1"></v-col>
          <v-col>
            <img width="50" :src="images.personalImageAddress">
          </v-col>
          <v-col>
            <img width="50" :src="images.nationalCardImageAddress" alt="">
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1">
            <span>وضعیت</span>
          </v-col>
          <v-col cols="3">
            <input class="disableInput" :style="images.lastConfirmationType === 'Accepted' ? 'color : green' :'color : red'" :value="images.lastConfirmationType === 'Editable' ? 'قابل ویرایش' : (images.lastConfirmationType === 'Accepted' ? 'تایید شده' : (images.lastConfirmationType === 'ConfirmRequest' ? 'درخواست بررسی'  : 'رد شده'))">
          </v-col>
          <v-col>
            <v-btn style="color:white;background-color: #02b5a0!important;" @click="ImageShow('personalImageAddress')">کارت ملی
            </v-btn>
          </v-col>
          <v-col>
            <v-btn style="color:white;background-color: #02b5a0!important;" @click="ImageShow('nationalCardImageAddress')">تعهد
              نامه
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1">
            <span >توضیحات</span>
          </v-col>
          <v-col>
            <input class="Input" v-model="imageCommentConfirm">
          </v-col>
          <v-col>
            <v-btn color="success" @click="AcceptConfirmImage()" v-if="images.lastConfirmationType != 'Accepted'">تایید درخواست
            </v-btn>
          </v-col>
          <v-col>
            <v-btn color="error" @click="RejectConfirmImage()" v-if="images.lastConfirmationType != 'Rejected'">رد درخواست
            </v-btn>
          </v-col>
          <v-col>
            <v-btn style="color:white;background-color: #02b5a0!important;" @click="getDataModal(images.confirmations)">تاریخچه
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <hr>
      <div style="margin-top: 2rem;margin-bottom: 1rem" id="access">
        <v-row>
          <h4 class="panelTitle">اطلاعات بانکی</h4>
        </v-row>
        <v-row style="gap:9rem;">
          <v-col cols="3">
            <span >شماره کارت</span>
          </v-col>
          <v-col cols="3">
            <span >شماره شبا</span>
          </v-col>
          <v-col cols="2">
            <span>نام بانک</span>
          </v-col>
        </v-row>
        <v-row v-for="(bankAccount , index) in BankAccounts" :key="index">
          <v-col cols="2">
            <input  class="disableInput" :value="bankAccount['cardNumber']">
          </v-col>
          <v-col cols="3">
            <input style="width:230px;" class="disableInput" :value="bankAccount['iban']">
          </v-col>
          <v-col cols="2">
            <input  class="disableInput" :value="banknamefinding(bankAccount['cardNumber'])">
          </v-col>
          <v-col>
            <span v-if="bankAccount.lastConfirmationType === 'Rejected'" style="color:red;">تایید نشده </span>
            <span v-if="bankAccount.lastConfirmationType === 'Accepted'" style="color:green;"> تایید شده </span>
            <span v-if="bankAccount.lastConfirmationType === 'VerifiedBySystem'" style="color:green;">تایید شده توسط سیستم</span>
            <span v-if="bankAccount.lastConfirmationType === 'Editable'" style="color:red;"> قابل ویرایش </span>
            <span v-if="bankAccount.lastConfirmationType === 'ConfirmRequest'" style="color:green;">درخواست بررسی</span>
          </v-col>
          <v-col>
            <v-btn style="min-width: 95px!important;" color="success" v-if="bankAccount.lastConfirmationType != 'Accepted'" @click="AcceptConfirmBankAccount(bankAccount['id'])">تایید
            </v-btn>
          </v-col>
          <v-col>
            <v-btn style="min-width: 95px!important;" color="error" v-if="bankAccount.lastConfirmationType != 'Rejected'" @click="RejectConfirmBankAccount(bankAccount['id'])">رد
            </v-btn>
          </v-col>
          <v-col>
            <v-btn style="color:white;background-color: #02b5a0!important;min-width: 95px!important;" @click="getExternalAuthorizationBank(bankAccount['id'])">استعلام
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <hr>
      <div style="margin-top: 2rem;margin-bottom: 1rem">
        <v-row>
          <h4 class="panelTitle">دسترسی کاربر</h4>
        </v-row>
        <v-row>
          <v-col cols="1">
            <span>
              وضعیت معاملات
            </span>
          </v-col>
          <v-col cols="3">
            <v-btn color="error" @click="TradeDissallow()" v-if="tradeAllow === true"> غیر فعال شود</v-btn>
            <v-btn color="success" @click="TradeAllow()" v-if="tradeAllow === false">فعال شود</v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn style="color:white;background-color: #02b5a0!important;" @click="getAssetsStatus()">استعلام</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span>سطح دسترسی کاربر</span>
          </v-col>
          <v-col v-for="(item,index) in UserRole" :key="index" style="margin-right:3rem;">
            <span >{{ item }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <select id="user_levels" name="user_levels" class="InputSelect" v-model="userAccessLevel">
              <option value="" selected disabled hidden>لطفا انتخاب کنید</option>
              <option v-for="(role, index) in roles" :key="index" :value="role">{{ role }}</option>
            </select>
          </v-col>
          <v-col>
            <v-btn color="success" @click="applyUserAccessLevel()">افزودن دسترسی</v-btn>
          </v-col>
          <v-col>
            <v-btn color="error" @click="removeUserAccessLevel()">حذف دسترسی</v-btn>
          </v-col>
        </v-row>
      </div>
      <hr>
      <div style="margin-top: 2rem;margin-bottom: 1rem" v-if="referralShare">
        <v-row>
          <h4 class="panelTitle">اطلاعات کمپین</h4>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-text-field v-model="referralShare.referralSharePercentage" label="درصد" outlined disabled></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field v-model="referralShare.referralShareType" label="نوع رفرال" outlined disabled></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field v-model="referralShare.campaignStartTimeAtUtc" label="تاریخ شروع" outlined disabled></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field v-model="referralShare.campaignFinishTimeAtUtc" label="تاریخ پایان" outlined disabled></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field v-model="referralShare.campaignTitle" label="نام کمپین" outlined disabled></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field v-model="referralShare.modifierUserName" label="دعوت شده توسط" outlined disabled></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <div style="margin-top: 12px!important;">
              <span>تغییر مقدار کارمزد : </span>
            </div>
          </v-col>
          <v-col cols="2">
            <v-text-field v-model="referralSharePercentageUpdate" type="number" label="تغییر درصد" outlined></v-text-field>
          </v-col>
          <v-col cols="2">
            <div style="margin-top: 12px" class="whiteButton">
              <v-btn @click="AddSpecialUserReferralShare()">اعمال درصد</v-btn>
            </div>
          </v-col>
          <v-col cols="2">
            <div style="margin-top: 12px!important;margin-top:18px;">
              <span style="white-space:nowrap;">برگشت به حالت پیشفرض : </span>
            </div>
          </v-col>
          <v-col cols="2">
            <div style="margin-top: 12px" class="whiteButton">
              <v-btn @click="DisableSpecialUserReferralShare()">تغییر به حالت پیشفرض</v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
      <hr v-if="UserFinancialLevel">
      <div style="margin-top: 2rem;margin-bottom: 1rem" v-if="UserFinancialLevel">
        <v-row>
          <h4 class="panelTitle">اطلاعات کمپین سیستم</h4>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-text-field label="exchangeFeePercent" v-model="UserFinancialLevel.item.exchangeFeePercent" outlined disabled></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field label="code" v-model="UserFinancialLevel.item.code" outlined disabled></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field label="title" v-model="UserFinancialLevel.item.title" outlined disabled></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field label="totalLastMonthTransactions" v-model="UserFinancialLevel.item.totalLastMonthTransactions" outlined disabled></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field v-model="UserFinancialLevel.type" label="نوع رفرال" outlined disabled></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="UserFinancialLevel.campaignStartTimeAtUtc" label="تاریخ شروع" outlined disabled></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="UserFinancialLevel.campaignFinishTimeAtUtc" label="تاریخ پایان" outlined disabled></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="UserFinancialLevel.campaignTitle" label="نام کمپین" outlined disabled></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <div style="margin-top: 12px!important;margin-top:18px;">
              <span >تغییر مقدار کارمزد : </span>
            </div>
          </v-col>
          <v-col cols="3">
            <div style="margin-top: 12px" class="whiteButton">
              <v-btn @click="show = !show">اضافه کردن صطح خاص برای کاربر</v-btn>
            </div>
          </v-col>
          <v-col cols="2">
            <div style="margin-top: 12px!important;margin-top:18px;">
              <span style="white-space:nowrap;">برگشت به حالت پیشفرض : </span>
            </div>
          </v-col>
          <v-col cols="2">
            <div style="margin-top: 12px" class="whiteButton">
              <v-btn @click="DisableSpecialUserFinancialLevel()">تغییر به حالت پیشفرض</v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
      <div v-if="UserReferralInfo" style="margin:20px 0 0 0;">
        <v-row>
          <v-col cols="1">
            <span >دعوت شده توسط</span>
          </v-col>
          <v-col cols="3">
            <input class="disableInput" v-model="UserReferralInfo.invitorUsername">
          </v-col>
          <v-col cols="1">
            <span >ثبت نام با کد</span>
          </v-col>
          <v-col cols="3">
            <input class="disableInput" v-model="UserReferralInfo.joinedByCode">
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1">
            <span >کد دعوت کاربر</span>
          </v-col>
          <v-col cols="3">
            <input class="disableInput" v-model="UserReferralInfo.referralCode">
          </v-col>
          <!--          <v-col cols="3">-->
          <!--            <span >ثبت نام با کد :</span>-->
          <!--          </v-col>-->
          <!--          <v-col cols="3">-->
          <!--            <input class="disableInput" v-model="UserReferralInfo.joinedByCode">-->
          <!--          </v-col>-->
        </v-row>
        <hr style="margin: 2rem 0">
        <v-row>
          <v-col cols="1" style="margin-right:16px;">
            <span>واریز به موجودی کاربر </span>
          </v-col>
          <v-col>
            <v-btn @click="BounsPopup = !BounsPopup" color="success">جزییات</v-btn>
          </v-col>
          <v-col></v-col>
        </v-row>
      </div>
    </v-card>

    <Popup :show.sync="show" width="900px">
      <template slot="body">
        <v-toolbar color="primary" dark class="rtl">
          اضافه کردن کمپین
        </v-toolbar>
        <v-card-text class="rtl" style="margin-top:1rem;">
          <slot name="body"></slot>
          <div class="row" style="margin-top:20px">
            <v-text-field v-model="newCampaigns[0].title" label="عنوان" outlined></v-text-field>
            <v-text-field v-model="newCampaigns[0].code" label="کد" outlined></v-text-field>
            <v-text-field v-model="newCampaigns[0].exchangeFeePercent" label="درصد" outlined></v-text-field>
            <v-text-field v-model="newCampaigns[0].totalLastMonthTransactions" label="کل معاملات 1 ماه گذشته" outlined></v-text-field>
          </div>
          <div class="row" style="margin-top:20px">
            <v-text-field v-model="newCampaigns[1].title" label="عنوان" outlined></v-text-field>
            <v-text-field v-model="newCampaigns[1].code" label="کد" outlined></v-text-field>
            <v-text-field v-model="newCampaigns[1].exchangeFeePercent" label="درصد" outlined></v-text-field>
            <v-text-field v-model="newCampaigns[1].totalLastMonthTransactions" label="کل معاملات 1 ماه گذشته" outlined></v-text-field>
          </div>
          <div class="row" style="margin-top:20px">
            <v-text-field v-model="newCampaigns[2].title" label="عنوان" outlined></v-text-field>
            <v-text-field v-model="newCampaigns[2].code" label="کد" outlined></v-text-field>
            <v-text-field v-model="newCampaigns[2].exchangeFeePercent" label="درصد" outlined></v-text-field>
            <v-text-field v-model="newCampaigns[2].totalLastMonthTransactions" label="کل معاملات 1 ماه گذشته" outlined></v-text-field>
          </div>
          <hr style="border-top-color: white">
          <v-btn color="success" @click="AddSpecialUserFinancialLevel()">اضافه کردن</v-btn>
        </v-card-text>
      </template>
    </Popup>
    <Popup :show.sync="ExternalAuthorizationBankShow">
      <template slot="body">
        <v-toolbar color="primary" dark class="rtl">
          جزییات استعلام
        </v-toolbar>
        <v-card-text class="rtl" style="margin-top:1rem;">
          <slot name="body"></slot>
          <v-card >
            <!--            <div>-->
            <!--              <div class="modal-content form-group" style="width: auto">-->
            <!--                <div style="padding: 4%">-->
            <!--                  <p style="font-size: 15px">جزعیات استعلام</p>-->
            <!--                </div>-->
            <!--                <div class="col-12"-->
            <!--                     style="height: 40px; transition: 0.3s;direction: ltr;font-size: 13px;padding-top: 9px">-->
            <!--                  <div class="row">-->
            <!--                    <div class="col-3">نام و نام خوانوادگی شماره شبا</div>-->
            <!--                    <div class="col-3">نام و نام خوانوادگی شماره کارت</div>-->
            <!--                    <div class="col-3">نام خوانوادگی در ساترکس</div>-->
            <!--                    <div class="col-3"> نام در ساترکس</div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <div class="col-12"-->
            <!--                     style="height: 300px;overflow: auto; transition: 0.3s;direction: ltr;font-size: 13px;margin-top: 1%">-->
            <!--                  <div class="row enterReport"-->
            <!--                       style="height: 40px;padding-top: 5px;padding-bottom: 8px;margin-bottom: 10px">-->
            <!--                    <div class="col-3"><span></span>{{ ExternalAuthorizationBank.ibanOwnerFullName }}</div>-->
            <!--                    <div class="col-3"><span></span>{{ ExternalAuthorizationBank.cardNumberOwnerFullName }}</div>-->
            <!--                    <div class="col-3"><span>{{ ExternalAuthorizationBank.lastName }}</span></div>-->
            <!--                    <div class="col-3"><span>{{ ExternalAuthorizationBank.firstName }}</span></div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <hr style="border-top-color: white">-->
            <!--              </div>-->
            <!--            </div>-->
            <v-card-title >
              جزعیات استعلام
              <v-spacer></v-spacer>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details class="searchStyle"></v-text-field>
            </v-card-title>
            <v-data-table :headers="headers" :items="ExternalAuthorizationBank" :search="search">
              <template v-slot:item.cardNumberOwnerFullName="{ item }">
                {{ item.cardNumberOwnerFullName != null ? item.cardNumberOwnerFullName : 'شماره کارت اشتباه است' }}
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </template>

    </Popup>
    <Popup :show.sync="assetListStatusShow">
      <template slot="body">
        <v-toolbar color="primary" dark class="rtl">
          جزییات
        </v-toolbar>
        <v-card-text class="rtl" style="margin-top:1rem;">
          <slot name="body"></slot>
          <v-card>
            <v-card>
              <v-card-title>
                جزعیات استعلام وضعیت ترید کاربر
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details class="searchStyle"></v-text-field>
              </v-card-title>
              <v-data-table :headers="AssetsHeadersStatus" :items="assetsStatus" :search="search">
                <template v-slot:item.operations="{ item }">
                  <v-row style="justify-content:center;">
                    <span @click="AssetStatusTradeDissAllow(item.id)" v-if="item.tradeAllowed === true " class="redButton">معاملات بسته شود </span>
                    <span @click="AssetStatusTradeAllow(item.id)" v-if="item.tradeAllowed === false " class="greenButton">معاملات باز شود </span>
                  </v-row>
                </template>
              </v-data-table>
            </v-card>
          </v-card>
        </v-card-text>
      </template>

    </Popup>
    <Popup :show.sync="historyShow">
      <template slot="body" >
        <v-toolbar color="primary" dark class="rtl">
          تاریخچه
        </v-toolbar>
        <v-card-text class="rtl" style="margin-top:1rem;">
          <slot name="body"></slot>
          <v-card >
            <v-card-title>
              تاریخچه
              <v-spacer></v-spacer>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details class="searchStyle"></v-text-field>
            </v-card-title>
            <v-data-table :headers="historyHeaders" :items="dataModal" :search="search">
              <template v-slot:item.createdAtUtc="{ item }">
                {{ getPersianDate(item.createdAtUtc) }}
              </template>
              <template v-slot:item.confirmationType="{ item }">
                {{
                item.confirmationType === 'ConfirmRequest' ? 'درخواست بررسی' : (item.confirmationType === 'Rejected' ? 'رد شده' : (item.confirmationType === 'Accepted' ? 'قبول شده' : 'قابل تغییر'))
                }}
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </template>
    </Popup>
    <Popup :show.sync="imageShow" width="700">
      <template slot="body">
        <v-row style="margin: 2rem">
          <v-col></v-col>
          <v-col>
            <img width="400" :src="images.personalImageAddress" v-if="selectedImage === 'personalImageAddress'" alt="">
            <img width="400" :src="images.nationalCardImageAddress" v-if="selectedImage === 'nationalCardImageAddress'" alt="">
          </v-col>
          <v-col></v-col>
        </v-row>
      </template>
    </Popup>
    <Popup width="900px" :show.sync="BounsPopup">
      <template slot="body">
        <v-toolbar color="primary" dark class="rtl">
          انتقال به حساب باشگاه مشتریان
        </v-toolbar>
        <v-card-text class="rtl" style="margin-top:1rem;">
          <v-row style="margin-top: 20px">
            <v-col cols="5">
              <span>انتخاب رمز ارز</span>
            </v-col>
            <v-col cols="3">
              <select id="accounting_rial_coin_options" class="InputSelect" v-model="selectedAsset">
                <option value="" selected disabled hidden>لطفا انتخاب کنید</option>
                <option :value="asset.id" v-for="(asset,index) in assets" :key="index">{{ asset.persianTitle }}</option>
              </select>
            </v-col>
          </v-row>
          <div>
            <div class="modal-content form-group" style="width: auto">
              <v-row style="margin-top:8px;">
                <v-col cols="5" style="height:65px;padding-top:20px;">
                  <p>مقدار را وارد کنید</p>
                </v-col>
                <v-col cols="3" style="height:65px;">
                  <v-text-field outlined v-model="volumeBonus" label="مقدار   : "></v-text-field>
                </v-col>
              </v-row>
              <v-row style="margin-top:8px;">
                <v-col cols="5" style="height:65px;padding-top:20px;">
                  <p >توضیحات</p>
                </v-col>
                <v-col cols="3" style="height:65px;">
                  <v-text-field outlined v-model="commentBonus" label="توضیحات   : "></v-text-field>
                </v-col>
              </v-row>

              <div style="margin-right: 400px;margin-top: 40px">
                <v-btn color="success" @click="SendGeneralBonus()">تایید </v-btn>
              </div>

              <!-- <hr style="border-top-color: white"> -->
            </div>
          </div>
        </v-card-text>
      </template>
    </Popup>
  </v-container>
</template>

<script>
import axiosApi from "@/axios";
import moment from 'jalali-moment';
import Vue from "vue";
import Popup from "@/components/Popup";
import getPersianDateTime from "@/plugins/getPersianDate";

export default {
  name: "DocumentUserManage",
  components: {
    Popup
  },
  data() {
    return {
      PersonalInfo: {},
      BounsPopup: false,
      newCampaigns: [
        {
          "title": "",
          "code": "",
          "exchangeFeePercent": 0,
          "totalLastMonthTransactions": 0,
        },
        {
          "title": "",
          "code": "",
          "exchangeFeePercent": 0,
          "totalLastMonthTransactions": 0,
        },
        {
          "title": "",
          "code": "",
          "exchangeFeePercent": 0,
          "totalLastMonthTransactions": 0,
        },
      ],
      phoneNumber: '',
      address: '',
      BankAccounts: '',
      PersonalInfoConfirm: '',
      PersonalInfoCommentConfirm: '',
      isLoading: false,
      imageShow: false,
      show: false,
      selectedImage: '',
      tradeAllow: '',
      addressConfirm: '',
      addressCommentConfirm: '',
      roles: [],
      UserRole: [],
      userAccessLevel: '',
      images: [],
      imageCommentConfirm: '',
      dataModal: [],
      picked: '',
      personType: '',
      ExternalAuthorizationBank: [],
      assetsStatus: [],
      historyShow: false,
      assetListStatusShow: false,
      ExternalAuthorizationBankShow: false,
      search: '',
      historyHeaders: [
        {
          text: 'نام مدیر ',
          align: 'start',
          sortable: false,
          value: 'issuerFullName',
        },
        { text: 'تاریخ', value: 'createdAtUtc' },
        { text: 'وضعیت', value: 'confirmationType' },
        { text: 'توضیحات', value: 'comment' },
      ],
      AssetsHeadersStatus: [
        {
          text: 'آی دی رمز ارز',
          align: 'start',
          sortable: false,
          value: 'asset.id',
        },
        { text: 'نام رمز ارز', value: 'asset.persianTitle' },
        { text: 'نماد', value: 'asset.symbol' },
        { text: 'وضعیت', value: 'operations' },
      ],
      headers: [
        {
          text: ' نام در ساترکس',
          align: 'start',
          sortable: false,
          value: 'firstName',
        },
        { text: 'نام خوانوادگی در ساترکس', value: 'lastName' },
        { text: 'نام و نام خوانوادگی شماره کارت', value: 'cardNumberOwnerFullName' },
        { text: 'نام و نام خوانوادگی شماره شبا', value: 'ibanOwnerFullName' },
      ],
      color: 'red',
      userLevel: '',
      referralShare: '',
      referralSharePercentageUpdate: 0,
      UserFinancialLevel: '',
      UserReferralInfo: '',
      assets: '',
      selectedAsset: '',
      commentBonus: '',
      volumeBonus: '',
      //////////////////////////
      panelGreen: "background-color: rgb(2,181,160) !important; color:white;",
      panelRed: "background-color: rgb(256,82,82) !important; color:white;"
    };
  },
  mounted() {
    this.getUserLevel();
    this.getUserInfo();
    this.GetPersonalInfo();
    this.getBankAccounts();
    this.getTradeAllow();
    this.GetAddressInfo();
    this.getRolesList();
    this.getUserImage();
    this.getUserRolesList();
    this.GetUserReferralShare();
    this.GetUserFinancialLevel();
    this.GetUserReferralInfo();
    this.getAssets();
  }
  ,
  methods: {
    SendGeneralBonus() {
      axiosApi().post('api/Manage/v1/CustomerClub/SendGeneralBonus', {
        "username": this.$route.params.username,
        "assetId": this.selectedAsset,
        "volume": this.volumeBonus,
        "comment": this.commentBonus
      }).then(({ data }) => {
        if (data['isSuccess']) {
          this.BounsPopup = false;
          Vue.$toast.open({
            message: ' عملیات با موفقیت انجام شد',
            type: 'success',
            position: 'top-right'
          });
        } else {
          Vue.$toast.open({
            message: ' موجودی کافی نیست',
            type: 'error',
            position: 'top-right'
          });
        }
      });
    },
    getAssets() {
      axiosApi().post('api/Manage/v1/Asset/GetList')
        .then(({ data }) => {
          if (data['isSuccess'] === true)
            this.assets = data.data;
        });
    },
    GetUserReferralInfo() {
      axiosApi().post('api/Manage/v1/ReferralShare/GetUserReferralInfo', {
        username: this.$route.params.username
      })
        .then((result) => {
          this.UserReferralInfo = result.data.data;
        });
    },
    GetUserReferralShare() {
      axiosApi().post('api/Manage/v1/ReferralShare/GetUserReferralShare', {
        username: this.$route.params.username
      })
        .then((result) => {
          this.referralShare = result.data.data;
          this.referralShare.campaignStartTimeAtUtc = getPersianDateTime(this.referralShare.campaignStartTimeAtUtc);
          this.referralShare.campaignFinishTimeAtUtc = getPersianDateTime(this.campaignFinishTimeAtUtc);
        });
    },
    DisableSpecialUserFinancialLevel() {
      axiosApi().post('api/Manage/v1/FinancialLevel/DisableSpecialUserFinancialLevel', {
        username: this.$route.params.username,
      })
        .then(() => {
          Vue.$toast.open({
            message: 'به روز رسانی انجام شد',
            type: 'success',
            position: 'top-right'
          });
          this.GetUserFinancialLevel();
        });
    },
    AddSpecialUserFinancialLevel() {
      axiosApi().post('api/Manage/v1/FinancialLevel/AddSpecialUserFinancialLevel', {
        username: this.$route.params.username,
        items: this.newCampaigns
      })
        .then(({ data }) => {
          if (data['isSuccess'] === true) {
            this.GetUserFinancialLevel();
            Vue.$toast.open({
              message: 'به روز رسانی انجام شد',
              type: 'success',
              position: 'top-right'
            });
          }

        });
    },
    GetUserFinancialLevel() {
      axiosApi().post('api/Manage/v1/FinancialLevel/GetUserFinancialLevel', {
        username: this.$route.params.username
      })
        .then((result) => {
          this.UserFinancialLevel = result.data.data;
          this.UserFinancialLevel.campaignStartTimeAtUtc = getPersianDateTime(this.UserFinancialLevel.campaignStartTimeAtUtc);
          this.UserFinancialLevel.campaignFinishTimeAtUtc = getPersianDateTime(this.UserFinancialLevel.campaignFinishTimeAtUtc);
        });
    },
    AddSpecialUserReferralShare() {
      if (this.referralSharePercentageUpdate === '') {
        Vue.$toast.open({
          message: 'لطفا درصد را وارد کنید',
          type: 'error',
          position: 'top-right'
        });
        return false;
      }
      axiosApi().post('api/Manage/v1/ReferralShare/AddSpecialUserReferralShare', {
        username: this.$route.params.username,
        referralSharePercentage: parseFloat(this.referralSharePercentageUpdate)
      })
        .then(() => {
          Vue.$toast.open({
            message: 'به روز رسانی انجام شد',
            type: 'success',
            position: 'top-right'
          });
          this.GetUserReferralShare();
        });
    },
    DisableSpecialUserReferralShare() {
      axiosApi().post('api/Manage/v1/ReferralShare/DisableSpecialUserReferralShare', {
        username: this.$route.params.username,
      })
        .then(() => {
          Vue.$toast.open({
            message: 'به روز رسانی انجام شد',
            type: 'success',
            position: 'top-right'
          });
          this.GetUserReferralShare();
        });
    },
    getUserRolesList() {
      axiosApi().post('api/Manage/v1/Role/GetListByUsername', {
        username: this.$route.params.username
      }).then(({ data }) => {
        this.UserRole = data.data;
      });
    },
    getBankAccounts() {
      axiosApi().post(
        "api/Manage/v1/BankAccounts/GetBankAccountListByUsername", {
        username: this.$route.params.username
      }
      ).then(({ data }) => {
        if (data['isSuccess'] == true)
          this.BankAccounts = data.data;
      });
    },
    AcceptConfirmBankAccount(id) {
      axiosApi().post('api/Manage/v1/BankAccounts/AcceptConfirmRequest', {
        username: this.$route.params.username,
        comment: '',
        "additionalId": id
      })
        .then(({ data }) => {
          if (data['isSuccess'] === true)
            this.getBankAccounts(),
              Vue.$toast.open({
                message: ' اکانت بانکی کاربر با موفقیت تایید شد ',
                type: 'success',
                position: 'top-right'
              });
          else
            data.Errors.forEach((item) => {
              Vue.$toast.open({
                message: item.Description,
                type: 'error',
                position: 'top-right'
              });
            });
        });
    },
    RejectConfirmBankAccount(id) {
      axiosApi().post('api/Manage/v1/BankAccounts/RejectConfirmRequest', {
        username: this.$route.params.username,
        comment: '',
        "additionalId": id
      })
        .then(({ data }) => {
          if (data['isSuccess'] === true)
            this.getBankAccounts(),
              Vue.$toast.open({
                message: ' اکانت بانکی کاربر با موفقیت رد شد ',
                type: 'success',
                position: 'top-right'
              });
          else
            data.Errors.forEach((item) => {
              Vue.$toast.open({
                message: item.Description,
                type: 'error',
                position: 'top-right'
              });
            });
        });
    },
    GetPersonalInfo() {
      axiosApi().post(
        "api/Manage/v1/PersonalInfo/Get?username=" + this.$route.params.username
      ).then(({ data }) => {
        if (data['isSuccess'] == true)
          this.PersonalInfo = data.data;
        this.picked = this.PersonalInfo.gender;
        this.personType = this.PersonalInfo.citizenId === null ? 'true' : 'false';
      },)

        .catch(
        //     err => {
        //   // if(401)
        //   // window.location.replace("/login.html")
        // }
      );
    },
    AcceptConfirmPersonalInfo() {
      axiosApi().post('api/Manage/v1/PersonalInfo/AcceptConfirmRequest', {
        username: this.$route.params.username,
        comment: this.PersonalInfoCommentConfirm
      })
        .then(({ data }) => {
          if (data['isSuccess'] === true)
            this.GetPersonalInfo(),
              Vue.$toast.open({
                message: 'اطلاعات شخصی کاربر با موفقیت تایید گردید',
                type: 'success',
                position: 'top-right'
              });
          else
            data.Errors.forEach((item) => {
              Vue.$toast.open({
                message: item.Description,
                type: 'error',
                position: 'top-right'
              });
            });
        });
    },
    RejectConfirmPersonalInfo() {
      axiosApi().post('api/Manage/v1/PersonalInfo/RejectConfirmRequest', {
        username: this.$route.params.username,
        comment: this.PersonalInfoCommentConfirm
      })
        .then(({ data }) => {
          if (data['isSuccess'] === true) {
            this.GetPersonalInfo();
            Vue.$toast.open({
              message: 'اطلاعات شخصی کاربر با موفقیت رد شد',
              type: 'success',
              position: 'top-right'
            });
            this.RejectConfirmImage();
          } else
            data.Errors.forEach((item) => {
              Vue.$toast.open({
                message: item.Description,
                type: 'error',
                position: 'top-right'
              });
            });
        });
    },
    GetAddressInfo() {
      axiosApi().post(
        "api/Manage/v1/AddressInfo/Get", {
        username: this.$route.params.username
      }
      ).then(({ data }) => {
        if (data['isSuccess'] == true)
          this.address = data.data;
      },);
    }
    , AcceptConfirmAddress() {
      axiosApi().post('api/Manage/v1/AddressInfo/AcceptConfirmRequest', {
        username: this.$route.params.username,
        comment: this.addressCommentConfirm
      })
        .then(({ data }) => {
          if (data['isSuccess'] === true)
            this.GetAddressInfo(),
              Vue.$toast.open({
                message: 'آدرس کاربر با موفقیت تایید گردید',
                type: 'success',
                position: 'top-right'
              });
          else
            data.Errors.forEach((item) => {
              Vue.$toast.open({
                message: item.Description,
                type: 'error',
                position: 'top-right'
              });
            });
        });
    },
    RejectConfirmAddress() {
      axiosApi().post('api/Manage/v1/AddressInfo/RejectConfirmRequest', {
        username: this.$route.params.username,
        comment: this.addressCommentConfirm
      })
        .then(({ data }) => {
          if (data['isSuccess'] === true)
            this.GetAddressInfo(),
              Vue.$toast.open({
                message: 'آدرس کاربر با موفقیت رد شد',
                type: 'success',
                position: 'top-right'
              });
          else
            data.Errors.forEach((item) => {
              Vue.$toast.open({
                message: item.Description,
                type: 'error',
                position: 'top-right'
              });
            });
        });
    }
    , getBirthdate() {
      return moment(this.PersonalInfo.birthDate).locale('fa').format('jYYYY/jMM/jDD');
    }
    , getPersianDate(date) {
      return getPersianDateTime(date);
    }
    ,
    getTradeAllow() {
      axiosApi().post('api/Manage/v1/User/GetList',
        {
          'username': this.$route.params.username
        }).then(({ data }) => {
          if (data['isSuccess'])
            this.tradeAllow = data.data['0']['tradeAllowed'];
        },);
    }
    , TradeAllow() {
      axiosApi().post('api/Manage/v1/User/TradeAllow',
        {
          username: this.$route.params.username
        })
        .then(({ data }) => {
          if (data['isSuccess'] === true)
            this.getTradeAllow();
          Vue.$toast.open({
            message: 'وضعیت ترید کاربر با موفقیت تغییر یافت',
            type: 'success',
            position: 'top-right'
          });
        });
    },
    TradeDissallow() {
      axiosApi().post('api/Manage/v1/User/TradeDisallow',
        {
          username: this.$route.params.username
        })
        .then(({ data }) => {
          if (data['isSuccess'] === true)
            this.getTradeAllow();
          Vue.$toast.open({
            message: 'وضعیت ترید کاربر با موفقیت تغییر یافت',
            type: 'success',
            position: 'top-right'
          });
        });
    },
    getRolesList() {
      axiosApi().post('api/Manage/v1/Role/GetList')
        .then(({ data }) => {
          if (data['isSuccess'] == true)
            this.roles = data.data;
        });
    },
    applyUserAccessLevel() {
      axiosApi().post('api/Manage/v1/Role/AssignRole?' + 'userName=' + this.$route.params.username + '&roleName=' + this.userAccessLevel)
        .then(({ data }) => {
          if (data['isSuccess'] == true)
            this.getUserRolesList();
          Vue.$toast.open({
            message: 'سطح کاربری با موفقیت اضافه گردید',
            type: 'success',
            position: 'top-right'
          });
        });
    },
    async getUserLevel() {
      try{
        const {data} = await axiosApi().post('/api/Manage/v1/QualifyLevel/GetUserQualifyLevel',{
          username: this.$route.params.username
        })
        if (data && data.data)
          this.userLevel = data.data.code
      }catch(e){
        console.log(e)
      }
    },
    removeUserAccessLevel() {
      axiosApi().post('api/Manage/v1/Role/UnassignRole?' + 'userName=' + this.$route.params.username + '&roleName=' + this.userAccessLevel)
        .then(({ data }) => {
          if (data['isSuccess'] == true)
            this.getUserRolesList();
          Vue.$toast.open({
            message: 'سطح کاربری با موفقیت حذف گردید',
            type: 'success',
            position: 'top-right'
          });
        });
    },
    getUserImage() {
      axiosApi().post('/api/Manage/v1/UserImage/Get?username=' + this.$route.params.username)
        .then(({ data }) => {
          if (data['isSuccess'] == true)
            this.images = data.data;
        });
    },
    AcceptConfirmImage() {
      axiosApi().post('api/Manage/v1/UserImage/AcceptConfirmRequest', {
        username: this.$route.params.username,
        comment: this.imageCommentConfirm
      })
        .then(({ data }) => {
          if (data['isSuccess'] == true)
            this.getUserImage(),
              Vue.$toast.open({
                message: 'عکس های کاربر با موفقیت تایید گردید',
                type: 'success',
                position: 'top-right'
              });
        });
    },
    RejectConfirmImage() {
      axiosApi().post('api/Manage/v1/UserImage/RejectConfirmRequest', {
        username: this.$route.params.username,
        comment: this.imageCommentConfirm
      })
        .then(({ data }) => {
          if (data['isSuccess'] == true)
            this.getUserImage(),
              Vue.$toast.open({
                message: 'عکس های کاربر با موفقیت رد شد',
                type: 'success',
                position: 'top-right'
              });
        });
    },
    banknamefinding(cart) {
      var cardnum = cart.substring(0, 6);
      switch (cardnum) {
        case "603799":
          return '    بانک ملی ایران';
        case "589210":
          return 'بانک سپه';
        case "627648":
          return 'بانک توسعه صادرات';
        case "627961":
          return "بانک صنعت و معدن";
        case "603770":
          return "بانک کشاورزی";
        case "628023":
          return "بانک مسکن";
        case "627760":
          return "پست بانک ایران";
        case "502908":
          return "بانک توسعه تعاون";
        case "627412":
          return "بانک اقتصاد نوین";
        case "622106":
          return "بانک پارسیان";
        case "502229":
          return "بانک پاسارگاد";
        case "627488":
          return "بانک کارآفرین";
        case "621986":
          return "بانک سامان";
        case "639346":
          return "بانک سینا";
        case "639607":
          return "بانک سرمایه";
        case "636214":
          return "بانک تات";
        case "502806":
          return "بانک شهر";
        case "502938":
          return "بانک دی";
        case "603769":
          return "بانک  صادرات";
        case "610433":
          return "بانک ملت";
        case "627353":
          return "بانک تجارت";
        case "589463":
          return "بانک رفاه";
        case "627381":
          return "بانک انصار";
        case "639370":
          return "بانک مهر اقتصاد";
        case "639599":
          return "بانک قوامین ";
        case "111111":
          return "شماره کارت اشتباه";
        case "222222":
          return "شماره کارت اشتباه";
        case "333333":
          return "شماره کارت اشتباه";
        case "444444":
          return "شماره کارت اشتباه";
        case "555555":
          return "شماره کارت اشتباه";
        case "666666":
          return "شماره کارت اشتباه";
        case "777777":
          return "شماره کارت اشتباه";
        case "888888":
          return "شماره کارت اشتباه";
        case "999999":
          return "شماره کارت اشتباه";
        case "000000":
          return "شماره کارت اشتباه";

      }
    },
    getDataModal(modalName) {
      this.dataModal = modalName;
      this.historyShow = true;
    },
    getExternalAuthorizationBank(id) {
      axiosApi().post('/api/Manage/v1/ExternalAuthorization/VerifyBankAccount',
        {
          id: id
        }).then(({ data }) => {
          this.ExternalAuthorizationBank = [];
          this.ExternalAuthorizationBank['0'] = data.data;
          this.ExternalAuthorizationBankShow = true;
        });

    },
    getUserInfo(){
      axiosApi().post('/api/Manage/v1/Account/Get', {
        username: this.$route.params.username
      }).then(({ data }) => {
        console.log({data})
      });
    },
    getAssetsStatus() {
      axiosApi().post('/api/Manage/v1/Wallet/GetList', {
        username: this.$route.params.username
      }).then(({ data }) => {
        this.assetsStatus = data.data;
        this.assetListStatusShow = true;
      });
    },
    AssetStatusTradeDissAllow(id) {
      axiosApi().post('/api/Manage/v1/Wallet/TradeDisallow', {
        id: id
      })
        .then(({ data }) => {
          if (data['isSuccess'])
            this.getAssetsStatus();
          Vue.$toast.open({
            message: 'تغییر وضعیت رمز ارز با موفقیت انجام گردید ',
            type: 'success',
            position: 'top-right'
          });
        });
    },
    AssetStatusTradeAllow(id) {
      axiosApi().post('/api/Manage/v1/Wallet/TradeAllow', {
        id: id
      })
        .then(({ data }) => {
          if (data['isSuccess'])
            this.getAssetsStatus();
          Vue.$toast.open({
            message: 'تغییر وضعیت رمز ارز با موفقیت انجام گردید ',
            type: 'success',
            position: 'top-right'
          });
        });
    },
    Scroll(type) {
      document.getElementById(type).scrollIntoView();
    },
    ImageShow(image) {
      this.imageShow = true;
      this.selectedImage = image;
    }
  }
};
</script>

<style scoped>
.divRow {
  border: 1px dashed #02b5a0;
  border-radius: 8px;
  padding: 6px;
  padding-right: 61px;
  margin-top: 20px;
}
.greenColor {
  color: rgb(2, 181, 160);
}
.redColor {
  color: red;
}
textarea {
  background-color: #eefdff;
  border-radius: 8px;
}
</style>
